<template>
  <div class="grid gap10">
    <input id="fileUpload" type="file" name="file" accept="image/*" @change="doUpload">
    <div class="grid-cell-1-3" v-for="(fh, idx) of fileHolders">
      <label class="file-upload-wrapper" @click="editable && !fh.fid && chooseFile(fh)">
        <div class="file-upload-inner">
          <template v-if="fh.fid">
            <img :src="toImgUrl(fh.fid)" @click="preview(toImgUrl(fh.fid))">
            <div class="file-upload-remove" @click="editable && removeFile(fh,$event)" v-if="editable">-</div>
          </template>
          <div class="file-upload-add" v-else>+</div>
        </div>
      </label>
    </div>
    <mt-popup
        v-model="previewPopShow"
        position="right" popup-transition="popup-fade" class="preview-popup">
      <img :src="previewUrl">
    </mt-popup>
  </div>
</template>

<style lang="scss" scoped>
#fileUpload {
  display: none;
}

.file-upload-wrapper {
  position: relative;
  padding-bottom: 100%;
  background: #eee;
  color: #ccc;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-upload-inner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  .file-upload-add {
    width: 100%;
    height: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-upload-remove {
    position: absolute;
    z-index: 3;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    font-size: 16px;
    //border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #666;
    color: #fff;
  }

  img {
    object-fit: cover;
  }
}

.preview-popup {
  width: 60%;
  left: 20%;
}
</style>

<script>
export default {
  name: "FileUpload",
  props: ["maxSize", "space", "biz", "bizId", "editable"],
  data() {
    return {
      curHolderIdx: 0,
      fileHolders: [],
      previewPopShow: false,
      previewUrl: ''
    }
  },
  mounted() {
    this.appendAddIfPossible()
  },
  methods: {
    initFiles(bizId){
      this.fileHolders = []
      const that = this
      //附件
      let query = `/fs/list?space=${this.space}`
      if (this.biz) {
        query += `&biz=${this.biz}`
      }
      if (this.bizId) {
        query += `&bizId=${this.bizId}`
      }
      this.$http.get(query).then(resp=>{
        const files = resp.data
        if (files && files.length>0){
          for (let i = 0; i < files.length; i++) {
            that.fileHolders.push({
              fid: files[i].uuid
            })
          }
        }
        that.appendAddIfPossible()
      })
    },
    getFids(){
      const fids = []
      for (let i = 0; i < this.fileHolders.length; i++) {
        const fid = this.fileHolders[i].fid
        if (fid) {
          fids.push(fid)
        }
      }
      return fids
    },
    toImgUrl(fid) {
      return `${this.imgUrl}/${fid}`
    },
    chooseFile(fh) {
      this.curHolderIdx = this.fileHolders.indexOf(fh)
      const el = document.getElementById("fileUpload")
      //android
      try {el.click()} catch (e) {}
      //ios
      try{el.dispatchEvent(new Event("click"))} catch (e){}
    },
    removeFile(fh, event){
      event.preventDefault()
      event.stopPropagation()
      this.fileHolders.splice(this.fileHolders.indexOf(fh), 1)
      this.appendAddIfPossible()
      this.$http.post(`/fs/delPhysic/${fh.fid}`)
    },
    appendAddIfPossible() {
      if (this.fileHolders.length < this.maxSize) {
        if (this.fileHolders.length>0 && !this.fileHolders[this.fileHolders.length-1].fid){
          return
        }
        if (this.editable) {
          this.fileHolders.push({
            fid: ''
          })
        }
      }
    },
    doUpload() {
      const that = this
      let fd = new FormData()
      let upload = document.getElementById("fileUpload")
      fd.append("file", upload.files[0])
      let query = `/fs/upload?space=${this.space}&zoom=2`
      if (this.biz) {
        query += `&biz=${this.biz}`
      }
      if (this.bizId) {
        query += `&bizId=${this.bizId}`
      }
      this.$http.post(query, fd, {
        headers: {'Content-Type': "multipart/form-data"}
      }).then(result => {
        if (result.success) {
          const f = result.data[0]
          that.fileHolders[that.curHolderIdx].fid = f.id
          that.appendAddIfPossible()
        }
      })
    },
    preview(imgUrl) {
      this.previewPopShow = true
      this.previewUrl = imgUrl
    }
  }
}
</script>
