<template>
  <div class="content-exp-score">
    <AppHeader :title="editable?'实验评价':'查看评价'"/>
    <div v-if="exp" class="line">
      <div class="flex-dir-row exp-line-title">
        {{ exp.equipment.name }}
        <div class="flex-item-auto exp-line-status">
          <div class="exp-line-status-circle"></div>
          <div v-if="exp.endAt!=null">已结束</div>
          <div v-else>实验中</div>
        </div>
      </div>
      <div class="applier">申请人：{{ exp.user.realName }}</div>
      <div>实验起止时间: {{ beginEnd(exp.beginAt, exp.endAt) }}</div>
      <div class="flex-dir-row" >
        实验时长: <span v-if="exp.endAt!=null">{{ displaySpent(exp.endAt - exp.beginAt) }}</span>
      </div>
    </div>

    <div v-if="exp" class="line">
      <div class="exp-line-title">
        实验评价
      </div>
      <div class="stars">
        <Rating :max-stars="5" :min-stars="1" :grade="exp.score>0?exp.score:5" ref="rating" :editable="editable"/>
      </div>
      <div class="grid gap10">
        <div class="grid-cell-1-3" v-for="(tag, idx) of tags">
          <div :class="`tag${tag.active?' active':' '}`" @click="toggleActive(tag)">{{ tag.text }}</div>
        </div>
        <div class="grid-cell-1">
          <textarea rows="10" placeholder="输入实验评价" v-model="remark" :disabled="!editable"></textarea>
        </div>
      </div>
      <div class="exp-line-title">
        结束状态采集
      </div>
      <FileUpload :space="space" :biz="biz" :biz-id="exp.id" :editable="editable" max-size="3" ref="fileUpload"/>
    </div>

    <div class="btn green" @click="doScore" v-if="editable">提交</div>
    <div class="btn green" @click="$router.back()" v-else>返回</div>
  </div>
</template>

<style lang="scss" scoped>
.exp-wrapper {
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fff;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.exp-line-status-circle {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 4px;
}

.applier {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 15px;
}

.content-exp-score {
  margin: 0;
  padding: 20px;
  background: linear-gradient(#31B8D3, #1682A7);
}

.stars {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag {
  height: 30px;
  line-height: 30px;
  border: 1px solid #E6E6E6;
  text-align: center;
  overflow: hidden;

  &.active {
    border: 1px solid #4A89DC;
    color: #4A89DC;
  }
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import Rating from '@/components/Rating.vue'
import {MessageBox, Toast} from 'mint-ui';
import store from "@/plugins/store";
import FileUpload from "@/components/FileUpload";

export default {
  components: {
    AppHeader,
    Panel,
    Rating,
    FileUpload
  },
  data() {
    return {
      exp: null,
      space: 'exDataFile',
      biz: '70',
      tags: [],
      remark: '',
      editable: true
    }
  },
  methods: {
    displaySpent(spent) {
      let totalSeconds = Math.floor(spent / 1000)
      let hours = Math.floor(totalSeconds / 3600)
      let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      let seconds = totalSeconds - hours * 3600 - minutes * 60
      return this.fillZero(hours) + ':' + this.fillZero(minutes) + ':' + this.fillZero(seconds)
    },
    fillZero(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    toggleActive(tag) {
      if (this.editable) {
        tag.active = !tag.active
      }
    },
    contains(str, search) {
      if (str) {
        return str.indexOf(search) >= 0
      }
      return false
    },
    doScore() {
      const that = this
      const scoreTagArr = []
      for (let tag of this.tags) {
        if (tag.active) {
          scoreTagArr.push(tag.id)
        }
      }
      //id, Integer score, String scoreTag, String remark
      const data = {
        id: this.$route.query.id,
        score: this.$refs.rating.stars,
        scoreTag: scoreTagArr.join(";"),
        remark: this.remark
      }
      this.$http.post(`/api/experiment/clientScore`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(resp => {
        if (resp.success) {
          Toast("评价成功！")
          setTimeout(function (){
            that.$router.push({path: '/exp'})
          },200)
        }
      })
    }
  },
  mounted() {
    const that = this
    that.editable = !this.$route.query.view
    //实验
    this.$http.get(`/api/experiment/info?id=${this.$route.query.id}`).then(resp => {
      that.exp = resp.data
      that.remark = this.exp.remark
      that.$http.get(`/api/dict?key=scoreTag&queryAll=true`).then(resp => {
        if (resp.results) {
          for (let kv of resp.results) {
            let id = kv.id
            let text = kv.text
            that.tags.push({
              id: id,
              text: text,
              active: that.contains(that.exp.scoreTag, id)
            })
          }
          //附件
          that.$nextTick(function () {
            that.$refs.fileUpload.initFiles(that.$route.query.id)
          })
        }
      })
    })
  }
}
</script>
