<template>
  <ul class="list">
    <li @click="editable && rate(star)" v-for="star in maxStars" :class="{ 'active': star <= stars }" :key="star.stars"
        class="star">
      <i :class="star <= stars ? 'my-icon-xlarge my-icon-star' : 'my-icon-xlarge my-icon-star-empty'"></i>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'Rating',
  props: ['grade', 'maxStars', 'minStars', 'editable'],
  data() {
    return {
      stars: this.grade
    }
  },
  methods: {
    rate(star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
        this.stars = this.stars === star ? Math.max(star - 1, this.minStars) : star
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list {
  padding: 0;

  .my-icon-xlarge {
    margin: 0 10px !important;
  }

  &:hover {
    .star {
      color: #ffe100;
    }
  }

  .star {
    display: inline-block;
    font-size: 42px;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      ~ .star:not(.active) {
        color: inherit;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      color: #ffe100;
    }

    i {
      display: block;
    }
  }
}

.info {
  margin-top: 15px;
  font-size: 40px;
  text-align: center;
  display: table;

  .divider {
    margin: 0 5px;
    font-size: 30px;
  }

  .score-max {
    font-size: 30px;
    vertical-align: sub;
  }
}
</style>